import React from 'react';
import { navigate } from 'gatsby'
import { Box, Card, CardHeader, CardBody, CardFooter, Heading,  Image, Paragraph } from 'grommet';

export default function SidebarRA() {
  const onClickButton_RAHome = () => {
    navigate ('/risk-analyst')
  }
  return (
    <Box pad={{top: '10px'}} direction="column" alignContent="between" gap="medium" style={{maxWidth: '320px', width: '100%', height: 'max-content'}}>
      <Card background="light-1" style={{borderRadius: '.5em', overflow: 'visible', boxShadow: 'none'}}>
      <CardHeader gap="small" justify="start">
        <Box style={{border: '4px solid #F8F8F8', borderRadius: '50%', margin: '-30px 0 0 20px', maxWidth: '110px', width: '100%'}}><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/6URm5DmCrvhpF0bakUpdAq/fcc0855e8faea6c8415ddc28c2a3ffbf/cta-avatar-2.png" style={{borderRadius: '50%'}} onClick={onClickButton_RAHome}/></Box>
        <Heading level={4} style={{paddingTop: '10px'}}>Risk Analyst</Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Paragraph style={{fontSize: '15px', lineHeight: '1.4', marginBottom: '20px'}}>Risk analysts identify, assess, and manage risks for an organization. They evaluate potential risks, develop risk mitigation strategies, and communicate their findings to management and stakeholders.</Paragraph>
          <Heading level={4} style={{marginBottom: '10px'}}>Challenges</Heading>
          <ul style={{fontSize: '15px', lineHeight: '1.5', marginBottom: '10px', padding: '0 0 0 15px'}}>
            <li>Dealing with uncertainties and  working with complex and potentially incomplete data</li>
            <li>Ensuring risk management strategies are effective in a rapidly changing environment</li>
            <li>Evaluating the potential impact of risk management strategies on profitability, reputation, and stakeholder interests</li>
          </ul>
        </CardBody>
      </Card>
      <Paragraph style={{fontSize: '18px', lineHeight: '1.5', fontWeight: '700', padding: '0 20px'}}>To learn more, reach out to your PwC Sponsor.</Paragraph>
    </Box>
  );
}